import { GoNumber } from "react-icons/go";
import { IoArrowBackOutline } from "react-icons/io5";
import { NumbersModal } from './modalComponent';
import { getCookie, deleteCookie } from "../controllers/apiController";
import Logo from '../assets/img/logo.png';
import { ReactComponent as Trevo } from "../assets/img/folha-de-trevo.svg";
import { useNavigate } from 'react-router-dom';
import { RiLogoutCircleLine, RiLoginCircleLine } from "react-icons/ri";
import { LogoutModal } from "./modalComponent"; 
import { useEffect, useState } from "react";


const Navbar = ({ openModalRifas, setOpenModalRifas, openLoginModal }) => {

    const loginCookie = getCookie('userId');
    const [login, setLogin] = useState(false);
    const [openLogout, setOpenLogout] = useState(false);
    

    useEffect(() => {
        setLogin(loginCookie);
    }, [loginCookie])

    const handleLogOut = () => {
        setOpenLogout(true);
    }

    const logout = () => {
        setOpenLogout(false);
        deleteCookie('userId');
        setLogin(false);
    }

    const handleLogin = () => {
        openLoginModal('login');
    }

    const handleMyNumbersClick = () => {
        if (login) {
            setOpenModalRifas(true);
        } else {
            openLoginModal("numeros");
        }
    }

    return (
        <>
            <div className='custom-navbar'>
                <div className="custom-navbar-container">
                    <img src={Logo} width={'120px'} height={'auto'} />
                    <div className="custom-navbar-buttons-container">
                        <button id='btn-meus-numeros' onClick={handleMyNumbersClick}><span className="custom-navbar-icon"><GoNumber /></span> <span className="custom-navbar-text">Meus Números</span></button>
                        {!login ?
                            <button id='btn-meus-numeros' onClick={handleLogin}><span className="custom-navbar-icon"><RiLoginCircleLine /></span> <span className="custom-navbar-text">Entrar</span></button>
                            :
                            <button id='btn-meus-numeros' onClick={handleLogOut}><span className="custom-navbar-icon"><RiLogoutCircleLine /></span> <span className="custom-navbar-text">Sair</span></button>
                        }
                    </div>
                </div>
            </div>
            <NumbersModal setBasicModal={setOpenModalRifas} basicModal={openModalRifas} />
            <LogoutModal setBasicModal={setOpenLogout} basicModal={openLogout} logout={logout}/>
        </>
    );
}

const NavbarThanks = ({ setLoginModal, congratulations, status }) => {

    const navigate = useNavigate();

    return (
        <>
            <div className='custom-navbar-thanks'>
                <div className="custom-navbar-container-thanks">
                    <div className="custom-navbar-buttons-container-thanks">
                        <button id='btn-meus-numeros' onClick={() => navigate('/')}>
                            <span className="custom-navbar-icon"><IoArrowBackOutline /></span>
                            <span className="custom-navbar-text">VOLTAR</span>
                        </button>
                    </div>
                    <div className="custom-navbar-logo-container-thanks">
                        <Trevo />
                        <p>
                            {congratulations} <br />
                            {status}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export { Navbar, NavbarThanks };