import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { NavbarThanks } from "../components/navbarComponent";
import { MdOutlineVerified } from "react-icons/md";
import Logo from '../assets/img/logo.png';
import { useState } from "react";
import { AuthModal, NumbersModal } from "../components/modalComponent";
import { GoNumber } from "react-icons/go";
import { getCookie } from "../controllers/apiController";

const ThanksPage = () => {
    const [authModal, setAuthModal] = useState(false);
    const [openModalRifas, setOpenModalRifas] = useState(false);    
    const login = getCookie('userId');

    return (
        <>
            <AuthModal basicModal={authModal} setBasicModal={setAuthModal} />
            <NumbersModal setBasicModal={setOpenModalRifas} basicModal={openModalRifas} />
            <NavbarThanks setLoginModal={setAuthModal} congratulations='Boa Sorte Jogador!' status='Pagamento efetuado com sucesso.' />
            <MDBContainer fluid className="thanks-page-container">
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="icon-obrigado"><MdOutlineVerified /></span>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <h1>Obrigado por jogar!</h1>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol size={8}>
                        <div className="d-flex justify-content-center align-items-center success-container">
                            <p className="success-hint">Seu pagamento foi processado com sucesso e seus números já estão disponíveis. Boa sorte!</p>
                            <button id='btn-meus-numeros' onClick={() => (login ? setOpenModalRifas(!openModalRifas) : setAuthModal(true))}>
                                <span className="icon"><GoNumber /></span> 
                                <span className="text">Veja seu números</span>
                            </button>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={Logo} className="logo-thanks-page" />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}

export default ThanksPage