import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { getCookie } from '../controllers/apiController';
import { useNavigate } from 'react-router-dom';
import '../assets/css/modal.css'

const RifaModal = ({ show, handleClose, campaign, openLoginModal }) => {
    const [ticketCount, setTicketCount] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        if (campaign) {
            setTicketCount(1); // Reset ticket count when a new campaign is passed
        }
    }, [campaign]);

    const incrementTicketCount = () => {
        if (ticketCount < 1000) {
            setTicketCount(ticketCount + 1);
        }
    };

    const decrementTicketCount = () => {
        if (ticketCount > 1) {
            setTicketCount(ticketCount - 1);
        }
    };

    const addTickets = (number) => {
        if (ticketCount + number > 1000) {
            setTicketCount(1000);
        } else {
            setTicketCount(ticketCount + number);
        }
    };

    const handleBuyRifa = () => {
        const login = getCookie('userId'); // Check login state

        if (!login) {
            handleClose(); // Close the current modal
            setTimeout(() => openLoginModal("compra"), 500); // Open login modal after a short delay
        } else {
            let quantity = ticketCount;
            let campaignId = campaign.campaign_id;
            let campaignDescription = campaign.name;
            navigate(`/pagamento?quantity=${quantity}&campaign_id=${campaignId}&campaign_description=${campaignDescription}`);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" className='modal'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='d-flex flex-column justify-content-evenly'>
                        {campaign.name}
                        <p className='p-0 m-0 modal-subtitle'>{campaign.description}</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {campaign.youtube_url && (
                    <div className="embed-responsive embed-responsive-16by9 mb-3 youtube-video-wrapper">
                        <iframe
                            className="embed-responsive-item"
                            src={campaign.youtube_url}
                            allowFullScreen
                            title="YouTube video player"
                        ></iframe>
                    </div>
                )}
                <p className='p-0 m-0'>Preço da unidade: <b>R${campaign.product_value}</b></p>
                <Row className="justify-content-center mt-3">
                    <Col className='buttons-wrapper'>
                        <Button variant="outline-primary" className='m-1 add-button' onClick={() => addTickets(10)}>+10</Button>
                        <Button variant="outline-primary" className='m-1 add-button' onClick={() => addTickets(25)}>+25</Button>
                        <Button variant="outline-primary" className='m-1 add-button' onClick={() => addTickets(50)}>+50</Button>
                        <Button variant="outline-primary" className='m-1 add-button' onClick={() => addTickets(100)}>+100</Button>
                        <Button variant="outline-primary" className='m-1 add-button' onClick={() => addTickets(250)}>+250</Button>
                        <Button variant="outline-primary" className='m-1 add-button' onClick={() => addTickets(500)}>+500</Button>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                    <Col md={4} className='d-flex justify-content-center align-items-center'>
                        <span className='icon-ticket-count' onClick={decrementTicketCount}><FaMinus /></span>
                        <div className='card-ticket-count'>
                            <Form.Control
                                type="number"
                                className='input-ticket-count'
                                value={ticketCount}
                                onChange={(e) => setTicketCount(Math.max(1, Math.min(1000, parseInt(e.target.value))))}
                                min="1"
                                max="1000"
                            />
                        </div>
                        <span className='icon-ticket-count' onClick={incrementTicketCount}><FaPlus /></span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='justify-content-around'>
                <Row className='justify-content-center align-items-center mt-3'>
                    <Col md={6}>
                        <div className='text-center'>
                            <p className='value-ticket'>
                                <span>Valor total</span>
                                {` ${(ticketCount * (campaign.product_value || 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Button variant="primary" onClick={handleBuyRifa}>
                    Comprar {ticketCount} rifa(s)
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RifaModal;
