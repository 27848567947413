import React, { useState, useEffect, useRef } from 'react';
import { createPayment, startPollingPaymentStatus } from '../controllers/apiController';
import { MDBSpinner, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';

const PaymentComponent = ({ qtdItems, campaignId, campaignDescription }) => {
  const tempoTotal = 30 * 60; // 30 minutos em segundos
  const navigate = useNavigate();
  const pollingRef = useRef(null);

  const [paymentData, setPaymentData] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [codePix, setCodePix] = useState('');
  const [tempoRestante, setTempoRestante] = useState(tempoTotal);

  useEffect(() => {
    handlePaymentCreation();
  }, []);

  const handlePaymentCreation = async () => {
    setLoading(true);
    try {
      const paymentResponse = await createPayment({ qtd_items: qtdItems, campaign_id: campaignId, campaign_description: campaignDescription });
      console.log(paymentResponse);
      if (paymentResponse && paymentResponse.success) {
        setPaymentData(paymentResponse);
        startPolling(paymentResponse.purchaseId);
        setCodePix(paymentResponse.pixQrCode);
      } else {
        console.error('Erro na criação do pagamento:', paymentResponse.error);
        navigate('/ops/')
      }
    } catch (error) {
      console.error('Erro ao criar o pagamento:', error);
      navigate('/ops/')

    } finally {
      setLoading(false);
    }
  };

  const startPolling = (purchaseId) => {
    pollingRef.current = startPollingPaymentStatus(purchaseId, (result) => {
      setPaymentStatus(result.status);
    });
  };

  // Função para interromper o polling
  const stopPolling = () => {
    console.log("Parando o polling:", pollingRef.current);
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  };


  // Efeito para limpar o polling quando o componente for desmontado
  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  const copyText = () => {
    if (!navigator.clipboard) {
      console.error('Clipboard não disponível neste navegador.');
      return;
    }

    navigator.clipboard.writeText(codePix)
      .then(() => {
        alert('Código copiado com sucesso.');
      })
      .catch(err => {
        console.error('Erro ao copiar texto: ', err);
      });
  };


  const formatarTempo = (segundos) => {
    const minutos = Math.floor(segundos / 60);
    const segundosRestantes = segundos % 60;
    return `${minutos} minuto(s) e ${segundosRestantes} segundo(s)`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTempoRestante(prevTempo => {
        const novoTempo = prevTempo > 0 ? prevTempo - 1 : 0;
        if (novoTempo === 0) {
          clearInterval(timer);
          setPaymentStatus('timeout');
        }
        return novoTempo;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (paymentStatus === 'approved') {
      navigate('/obrigado');
    } else if (paymentStatus === 'timeout' || paymentStatus === 'rejected' || paymentStatus === 'cancelled') {
      navigate('/ops');
    } else if (paymentStatus === 'inprocess') {
      navigate('/pendente');
    }
  }, [paymentStatus]);

  if (loading) {
    return (
      <div className='loader-container'>
        <MDBSpinner className='loader-qrcode'>
          <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
        <p>Registrando suas cotas e gerando QR Code para pagamento...</p>
      </div>
    );
  }

  return (
    <div>
      {paymentData && (
        <>
          <MDBRow className='justify-content-center align-items-center'>
            <MDBCol md={6}>
              <div>
                <p className='time-hint'>Tempo Restante <br /> {formatarTempo(tempoRestante)}</p>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow className='text-center mb-3 justify-content-center'>
            <MDBCol md={6}>
              <h4 className='mt-2 hint-qrcode'>Escaneie o QR Code abaixo com o app do seu banco para realizar o pagamento via PIX.</h4>
            </MDBCol>
            <MDBCol md={8}>
              <img width={'400px'} height={'400px'} src={`data:image/png;base64,${paymentData.pixQrCodeBase64}`} alt="PIX QR Code" />
            </MDBCol>
          </MDBRow>

          <MDBRow className='justify-content-center align-items-center'>
            <MDBCol md={4}>
              <div className="input-group mb-3">
                <input type="text" className="form-control" value={codePix} placeholder="Código para pagamento no PIX" disabled />
                <span className="input-group-text btn-copy" id="basic-addon2" onClick={copyText}>Copiar Código</span>
              </div>
            </MDBCol>
          </MDBRow>
        </>
      )}
    </div>
  );
};

export { PaymentComponent };
