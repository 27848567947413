import React from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit'
import { BsInstagram, BsTiktok } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

import SiteSeguroImg from '../assets/img/siteSeguro.png';
import PixLogotipo from '../assets/img/pixLogotipo.png';

const Footer = () => {
    return (
        <>
            <footer className='footer-container'>
                <MDBRow className='justify-content-center align-items center'>
                    <MDBCol md={3}>
                        <h5 className='second-font'>Site Confiável</h5>
                        <img src={SiteSeguroImg} width={'100%'} height={'auto'} />
                    </MDBCol>
                    <MDBCol md={4}>
                        <h5 className='text-center second-font'>Acesse nossas redes sociais</h5>
                        <div className='d-flex align-items-start justify-content-center'>
                            <div className='footer-icons-container align-items-start justify-content-center'>
                                <a href='https://www.instagram.com/thiagolarifa/' target='_blank'><BsInstagram /></a>
                                <a href='https://www.facebook.com/people/La-Rifa-Da-Sorte/61550115107213/' target='_blank'><FaFacebookF /></a>
                                <a href='https://www.tiktok.com/@thiagolarifa' target='_blank'><BsTiktok /></a>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md={3}>
                        <h5 className='second-font'>Pagamento Garantido</h5>
                        <img src={PixLogotipo} width={'70%'} height={'auto'} />

                    </MDBCol>
                </MDBRow>
            </footer>
        </>
    )
}

export { Footer }