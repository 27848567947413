import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBCard,
    MDBCardBody,
} from 'mdb-react-ui-kit';
import ImageR from '../assets/img/ticket-r.png';
import ImageL from '../assets/img/ticket-l.png';
import { FaPlus, FaMinus } from "react-icons/fa6";
import { FaqComponent } from './faqComponent';
import { Footer } from './footerComponent';
import { AuthModal } from './modalComponent';
import { Navbar } from './navbarComponent';
import { AnimatedDiv } from './animatedDiv';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { getCookie } from '../controllers/apiController';
import { api } from '../controllers/apiController'; // Import API
import { ReactComponent as BarraOuro } from '../assets/img/barra-ouro.svg';

const RifaPage = () => {
    const { id } = useParams(); // Get campaign ID from URL
    const [ticketCount, setTicketCount] = useState(1);
    const [authModal, setAuthModal] = useState(false);
    const [loginContext, setLoginContext] = useState("");
    const [openModalRifas, setOpenModalRifas] = useState(false);
    const [imageR, setImageR] = useState(null);
    const [imageL, setImageL] = useState(null);
    const navigate = useNavigate();
    const [data, setData] = useState({});

    const checkImageValid = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
        });
    };

    const loadImage = async (url, setImage) => {
        try {
            const isValid = await checkImageValid(url);
            if (isValid) {
                setImage(url);
            } else {
                console.error('Imagem inválida:', url);
            }
        } catch (error) {
            console.error('Erro ao carregar imagem:', error);
        }
    };

    const fetchCampaignData = async () => {
        try {
            const response = await fetch(`${api}/web/getCampaign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id })
            });
            const result = await response.json();
            console.log('API Response:', result); // Adicione esta linha para verificar a resposta da API

            if (result.data && result.data.length > 0) {
                setData(result.data[0]); // Acesse o primeiro item do array retornado
            } else {
                console.error('API returned an unexpected structure:', result);
            }

        } catch (error) {
            console.error('Erro ao buscar dados da campanha:', error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchCampaignData();
        }
    }, [id]);

    useEffect(() => {
        if (data && data.image_r_url) {
            loadImage(api + '/' + data.image_r_url, setImageR);
        }
        if (data && data.image_l_url) {
            loadImage(api + '/' + data.image_l_url, setImageL);
        }
    }, [data]);

    const handleGoPayment = () => {
        const login = getCookie('userId'); // Check login state

        if (!login) {
            openLoginModal("compra");
        } else {
            let quantity = ticketCount;
            let campaignId = data.campaign_id;
            let campaignDescription = data.name;
            navigate(`/pagamento?quantity=${quantity}&campaign_id=${campaignId}&campaign_description=${campaignDescription}`);
        }
    };

    const addTickets = (number) => {
        if (ticketCount < 1000) {
            let result = (parseInt(ticketCount) + parseInt(number));
            if (result > 1000) {
                setTicketCount(1000);
            } else {
                setTicketCount(result);
            }
        } else {
            alert(`Você já tem ${ticketCount} cotas selecionadas. Para adicionar mais, digite diretamente no campo de quantidade de cotas.`);
        }
    };

    const incrementTicketCount = () => {
        setTicketCount(!ticketCount ? 1 : parseInt(ticketCount) + 1);
    };

    const decrementTicketCount = () => {
        if (ticketCount > 0) {
            setTicketCount(parseInt(ticketCount) - 1);
        }
    };

    const handleLoginSuccess = () => {
        setAuthModal(false);
        if (loginContext === "numeros") {
            setOpenModalRifas(true);
        } else if (loginContext === "compra") {
            handleGoPayment();
        }
    };

    const openLoginModal = (context) => {
        setLoginContext(context);
        setAuthModal(true);
    };

    useEffect(() => {
        document.title = `Concorra a ${data.name || ''} por apenas ${data.product_value ? data.product_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''} cada cota! - Thiago La Rifa`;
    }, [data]);

    return (
        <>
            <Navbar setOpenModalRifas={setOpenModalRifas} openModalRifas={openModalRifas} openLoginModal={openLoginModal} />
            <MDBContainer className="text-center header-bg text-white py-3" fluid>
                <MDBRow className='justify-content-center align-items-center'>
                    <MDBCol md='8'>
                        <h1 className='header-title'>{data.name || 'Concorra a Prêmios Incríveis'}</h1>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            <AuthModal basicModal={authModal} setBasicModal={setAuthModal} onLoginSuccess={handleLoginSuccess} />

            <MDBRow className="justify-content-center">
                <MDBCol md={3} className='float-element left'>
                    <AnimatedDiv direction={'left'}>
                        <img alt='imagem flutuante de um ford ka' src={imageL} />
                    </AnimatedDiv>
                </MDBCol>
                <MDBCol md="6">
                    <AnimatedDiv direction={'top'} triggerOnScroll={false} velocity={2}>
                        <MDBCard className='card-details'>
                            <MDBCardBody className="text-center">
                                <iframe className='iframe-card-details' src={data.youtube_url || ''} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                <div className='card-info-container'>
                                    <h5>{data.name || 'Nome do Prêmio'}</h5>
                                    <p>{data.description || 'Subtítulo do Prêmio'}</p>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </AnimatedDiv>
                </MDBCol>
                <MDBCol md={3} className='float-element right'>
                    <AnimatedDiv direction={'right'}>
                        <img alt='imagem flutuante de uma moto' src={imageR} />
                    </AnimatedDiv>
                </MDBCol>
            </MDBRow>
            <MDBRow className='justify-content-center align-items-center'>
                <MDBCol md={6} className='text-center'>
                    <h2 className='cta-valor-cota'>{data.ctaTexto || 'Compre já sua rifa e concorra a prêmios incríveis!'}</h2>
                </MDBCol>
            </MDBRow>

            <MDBContainer className="justify-content-center py-5 bg-select-cotas select-cotas-container" fluid>
                <MDBRow className="justify-content-center">
                    <MDBCol size={4} className='d-flex justify-content-center align-items-center'>
                        <MDBBtn id='btn_15' onClick={() => addTickets(15)} className="ticket-selection-btn m-2 shadow-0 shadow-light">
                            <span className='qtd-ticket'>15</span>
                            <span className='desc-ticket'>Selecionar</span>
                            <span className='icon-ticket'><FaPlus /></span>
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol size={4} className='d-flex justify-content-center align-items-center'>
                        <MDBBtn id='btn_30' onClick={() => addTickets(30)} className="ticket-selection-btn m-2 shadow-0 shadow-light">
                            <span className='qtd-ticket'>30</span>
                            <span className='desc-ticket'>Selecionar</span>
                            <span className='icon-ticket'><FaPlus /></span>
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol size={4} className='d-flex justify-content-center align-items-center'>
                        <MDBBtn id='btn_60' onClick={() => addTickets(60)} className="ticket-selection-btn m-2 shadow-0 shadow-light">
                            <span className='qtd-ticket'>60</span>
                            <span className='desc-ticket'>Selecionar</span>
                            <span className='icon-ticket'><FaPlus /></span>
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol size={4} className='d-flex justify-content-center align-items-center'>
                        <MDBBtn id='btn_120' onClick={() => addTickets(120)} className="ticket-selection-btn m-2 shadow-0 shadow-light">
                            <span className='qtd-ticket'>120</span>
                            <span className='desc-ticket'>Selecionar</span>
                            <span className='icon-ticket'><FaPlus /></span>
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol size={4} className='d-flex justify-content-center align-items-center'>
                        <MDBBtn id='btn_240' onClick={() => addTickets(240)} className="ticket-selection-btn m-2 shadow-0 shadow-light">
                            <span className='qtd-ticket'>240</span>
                            <span className='desc-ticket'>Selecionar</span>
                            <span className='icon-ticket'><FaPlus /></span>
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol size={4} className='d-flex justify-content-center align-items-center'>
                        <MDBBtn id='btn_480' onClick={() => addTickets(480)} className="ticket-selection-btn m-2 shadow-0 shadow-light">
                            <span className='qtd-ticket'>480</span>
                            <span className='desc-ticket'>Selecionar</span>
                            <span className='icon-ticket'><FaPlus /></span>
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-center align-items-center'>
                    <MDBCol md={8} className='d-flex justify-content-center align-items-center'>
                        <div className="selected-tickets-counter my-4">
                            <MDBBtn id='btn_menos_tickets' className="counter-btn m-2" color="light" onClick={decrementTicketCount}>
                                <FaMinus />
                            </MDBBtn>
                            <input type='number' value={ticketCount} onChange={e => setTicketCount(e.target.value)}></input>
                            <MDBBtn id='btn_mais_tickets' className="counter-btn m-2" color="light" onClick={incrementTicketCount}>
                                <FaPlus />
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-center align-items-center'>
                    <MDBCol md={6}>
                        <div className='text-center'>
                            <p className='value-ticket'>
                                <span>Valor total</span>
                                {` ${(ticketCount * (data.product_value || 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                            </p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-center align-items-center'>
                    <MDBCol size={7} className='d-flex justify-content-center align-items-center'>
                        <MDBBtn onClick={handleGoPayment} id='btn-comprar' className="my-2 btn-buy shadow-0 shadow-light">
                            PARTICIPAR AGORA
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBRow className='justify-content-center align-items-center faq-container'>
                <MDBCol md={8}>
                    <div className='faq-header'>
                        <h2>{data.faqTitulo || 'Tire suas dúvidas:'}</h2>
                    </div>
                </MDBCol>
                <MDBCol md={6} className='faq-accordion-container'>
                    <FaqComponent className='mt-4 faq-component' />
                </MDBCol>
            </MDBRow>
            <MDBRow className='justify-content-center align-items-top'>
                <MDBCol className='text-center'>
                    <BarraOuro className='gold-bar' />
                </MDBCol>
            </MDBRow>
            <Footer />
        </>
    );
};

export default RifaPage;
