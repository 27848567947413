import React from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';

const NotFoundComponent = () => {
  return (
    <MDBContainer style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ color: 'red' }}>404 - Página Não Encontrada</h1>
      <p style={{ color: 'black' }}>Desculpe, a página que você está procurando não existe.</p>
      <p style={{ color: 'grey' }}>Talvez você queira voltar para a <a href="/" style={{ color: 'red' }}>página inicial</a>.</p>
    </MDBContainer>
  );
}

export { NotFoundComponent };
