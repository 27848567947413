import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { NavbarThanks } from "../components/navbarComponent";
import { useLocation } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import { useState } from "react";
import { AuthModal } from "../components/modalComponent";
import { PaymentComponent } from "../components/paymentComponent";

const PaymentPage = () => {
    const [authModal, setAuthModal] = useState();
    let location = useLocation();

    let searchParams = new URLSearchParams(location.search);
    let quantity = searchParams.get('quantity');
    let campaignId = searchParams.get('campaign_id');
    let campaignDescription = searchParams.get('campaign_description');

    return (
        <>
            <AuthModal basicModal={authModal} setBasicModal={setAuthModal} />
            <NavbarThanks setLoginModal={setAuthModal} congratulations='Boa Sorte Jogador!' status='Pagamento por PIX' />
            <MDBContainer fluid className="thanks-page-container">
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <h1>Obrigado por jogar!</h1>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <PaymentComponent
                            qtdItems={quantity}
                            campaignId={campaignId}
                            campaignDescription={campaignDescription}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={Logo} className="logo-thanks-page" />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}

export default PaymentPage;
