import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { NavbarThanks } from "../components/navbarComponent";
import { FaTimes } from "react-icons/fa";
import Logo from '../assets/img/logo.png';
import { AuthModal } from "../components/modalComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const [authModal, setAuthModal] = useState();
    const navigate = useNavigate();

    return (
        <>
            <AuthModal basicModal={authModal} setBasicModal={setAuthModal} />
            <NavbarThanks setLoginModal={setAuthModal} congratulations='Tente novamente' status='Não foi possível realizar seu pagamento.'/>
            <MDBContainer fluid className="thanks-page-container">
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <h1>Ops, parece que houve um problema...</h1>
                        </div>
                    </MDBCol>
                    <MDBCol md={4}>
                        <p className="mt-3 pending-warning">
                           Não foi possível processar seu pagamento, por favor tente novamente!</p>

                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="icon-pending"><FaTimes /></span>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <button onClick={() => navigate('/')} id='btn-try' className="my-2 btn-buy-link shadow-0 shadow-light">
                                Tentar novamente
                            </button>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}

export default ErrorPage