import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/cardRifa.css';
import rifaImage from '../assets/img/placeholder.png';
import { api } from '../controllers/apiController'; // Import API
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import RifaModal from './rifaModal'; // Import RifaModal
import { LuCrown } from "react-icons/lu";


const CardRifa = ({ campaign, openLoginModal }) => {
    const navigate = useNavigate();
    const [imageSrc, setImageSrc] = useState(rifaImage);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const checkImageValid = (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(true);
                img.onerror = () => resolve(false);
            });
        };

        const loadImage = async () => {
            const imageUrl = api + '/' + campaign.card_image_url;
            const isValid = await checkImageValid(imageUrl);
            if (isValid) {
                setImageSrc(imageUrl);
            } else {
                setImageSrc(rifaImage);
            }
        };

        if (campaign && campaign.card_image_url) {
            loadImage();
        } else {
            setImageSrc(rifaImage);
        }
    }, [campaign]);

    const handleBuyClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <MDBContainer className='p-0 m-0 card-container'>
                <div className="rifa-card">
                    <MDBRow className='p-0 m-0 content-wrapper'>
                        <MDBCol className='img-wrapper' size={4}>
                            <img src={imageSrc} alt="Rifa" />
                        </MDBCol>
                        <MDBCol className='p-2 m-0 text-wrapper'>
                            <div className="rifa-info">
                                <div>
                                    <h2 className='m-0'>{campaign.name}</h2>
                                    <p>{campaign.description}</p>
                                    {campaign.winner && (
                                        <h3 className="winner-text-a">Número Premiado: <b>{campaign.winner}</b></h3>
                                    )}
                                </div>
                                <div className="button-price">
                                    {!campaign.winner && (
                                        <span className="rifa-price">R${campaign.product_value}</span>
                                    )}
                                    {!campaign.winner && (
                                        <button className='button-confirm' onClick={handleBuyClick}>Comprar rifa</button>
                                    )}
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
            <RifaModal show={showModal} handleClose={handleCloseModal} campaign={campaign} openLoginModal={openLoginModal} />
        </>
    );
}

export default CardRifa;
