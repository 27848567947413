import React from 'react';
import RoutesController from './controllers/routesController';
import 'bootstrap/dist/css/bootstrap.min.css';

//Imports de CSS
import './assets/css/index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

function App() {
  return (
    <RoutesController />
  );
}

export default App;
