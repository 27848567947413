import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import { Navbar } from '../components/navbarComponent';
import { Footer } from '../components/footerComponent';
import CardRifa from '../components/cardRifa';
import HighlightedCardRifa from '../components/highlightedCardRifa';
import { api } from "../controllers/apiController";
import { AuthModal, NumbersModal, LogoutModal } from '../components/modalComponent'; // Import AuthModal and other modals
import banner from '../assets/img/headerBackground.png';
import vehicles from '../assets/img/footerimg.png';
import headerimg from '../assets/img/IMG_2554.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../assets/css/mainPage.css';
import '../assets/css/index.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Index = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [authModal, setAuthModal] = useState(false);
    const [numbersModal, setNumbersModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [loginContext, setLoginContext] = useState("");

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duração da animação em milissegundos
            once: true, // Se true, a animação ocorre apenas uma vez
        });
    }, []);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await fetch(`${api}/web/campaignsAll`);
                if (!response.ok) {
                    throw new Error('Erro ao buscar campanhas');
                }
                const data = await response.json();
                setCampaigns(data);
                console.log(data);
            } catch (error) {
                console.error('Erro ao buscar campanhas:', error);
            }
        };

        fetchCampaigns();
    }, []);

    const handleLoginSuccess = () => {
        setAuthModal(false);
        if (loginContext === "compra") {
            setNumbersModal(true); // Open numbers modal after login for purchase
        }
    };

    const openLoginModal = (context) => {
        setLoginContext(context);
        setAuthModal(true);
    };

    const openNumbersModal = () => {
        setNumbersModal(true);
    };

    const openLogoutModal = () => {
        setLogoutModal(true);
    };

    const highlightedCampaigns = campaigns.filter(campaign => campaign.focus);
    const regularCampaigns = campaigns.filter(campaign => !campaign.focus);

    return (
        <>
            <Navbar openLoginModal={openLoginModal} setOpenModalRifas={setNumbersModal} openModalRifas={numbersModal} />
            <MDBRow className='m-0'>
                <div className="cta-section">
                    <MDBContainer fluid className='m-0 p-0 hero-container' data-aos="fade-down">
                        <div className='card-info-container'>
                            <div className='hero-image-wrapper'>
                                <img src={headerimg} />
                            </div>
                        </div>
                    </MDBContainer>
                    <div className='cta-text' data-aos="fade-up">
                        <h2>Pronto para ser um vencedor?</h2>
                        <p className='p-0'>Selecione a rifa que deseja e garanta seu bilhete agora mesmo!</p>
                    </div>
                    <div className='rifas rifas-highlight' data-aos="fade-up" data-aos-delay="300">
                        <MDBCol md={12}>
                            <h1 className='p-0'>DESTAQUE</h1>
                        </MDBCol>
                        {highlightedCampaigns.map(campaign => (
                            <HighlightedCardRifa key={campaign.id} campaign={campaign} openLoginModal={openLoginModal} />
                        ))}
                    </div>
                    <p className='p-0 pt-4'>TODAS AS RIFAS</p>
                    <div className='rifas' data-aos="fade-up" data-aos-delay="300">
                        {regularCampaigns.map(campaign => (
                            <CardRifa key={campaign.id} campaign={campaign} openLoginModal={openLoginModal} openNumbersModal={openNumbersModal} />
                        ))}
                    </div>
                </div>

                <div className="image-before-footer">
                    <img src={vehicles} alt="Fundo de veiculos" data-aos="fade-up" data-aos-delay="600" />
                </div>
            </MDBRow>
            <Footer />
            <AuthModal basicModal={authModal} setBasicModal={setAuthModal} onLoginSuccess={handleLoginSuccess} />
            <NumbersModal basicModal={numbersModal} setBasicModal={setNumbersModal} />
            <LogoutModal basicModal={logoutModal} setBasicModal={setLogoutModal} logout={() => { /* add logout function */ }} />
        </>
    );
}

export default Index;
