import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { NavbarThanks } from "../components/navbarComponent";
import { PiClockCountdown } from "react-icons/pi";
import Logo from '../assets/img/logo.png';
import { useState } from "react";
import { AuthModal } from "../components/modalComponent";

const PendentePage = () => {
    const [authModal, setAuthModal] = useState();

    return (
        <>
            <AuthModal basicModal={authModal} setBasicModal={setAuthModal} />
            <NavbarThanks setLoginModal={setAuthModal} congratulations='Boa Sorte Jogador!' status='Seu pagamento está sendo processado.'/>
            <MDBContainer fluid className="thanks-page-container">
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <h1>Obrigado por jogar!</h1>
                        </div>
                    </MDBCol>
                    <MDBCol md={4}>
                        <p className="mt-3 pending-warning">Seu pagamento está sendo processado. Em alguns instantes você podera visualizar os números adquiridos em "Meus Números."</p>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="icon-pending"><PiClockCountdown /></span>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center align-items-center">
                    <MDBCol md={12}>
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={Logo} className="logo-thanks-page" />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}

export default PendentePage;