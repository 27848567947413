const api_dev = 'http://localhost:8000';
const api_prod = 'https://api.thiagolrpremiacoes.com'
const api = api_prod;

const getCookie = (nome) => {
  var nomeCookie = nome + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(nomeCookie) === 0) {
      return c.substring(nomeCookie.length, c.length);
    }
  }
  return "";
}

const deleteCookie = (name) => {
  // Define o cookie com uma data de expiração no passado
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


const removeNumbers = (str) => {
  return str.replace(/\D/g, '');
}

const createPayment = async (paymentData) => {

  const formdata = new FormData();
  formdata.append("qtd_items", paymentData.qtd_items);
  formdata.append('user_id', getCookie('userId'));
  formdata.append('campaign_id', paymentData.campaign_id);
  
  const requestOptions = {
    method: 'POST',
    body: formdata,
    credentials: 'include'
  };

  try {
    const response = await fetch(`${api}/payment/create`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

const checkPaymentStatus = async (purchaseId) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  };

  try {
    const response = await fetch(`${api}/payment/check-status/${purchaseId}`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao verificar o status do pagamento:', error);
    throw error;
  }
};

const startPollingPaymentStatus = (purchaseId, callback, interval = 5000) => {
  const polling = setInterval(async () => {
    try {
      const result = await checkPaymentStatus(purchaseId);

      if (result.status && result.status !== 'pending') {
        clearInterval(polling);
        callback(result);
      }
    } catch (error) {
      clearInterval(polling);
      console.error('Erro durante o polling:', error);
    }
  }, interval);

  return polling;
};

const login = async (loginData) => {
  const formdata = new FormData();
  formdata.append("cpf", removeNumbers(loginData.cpf));

  const requestOptions = {
    method: 'POST',
    body: formdata,
  };

  try {
    const response = await fetch(`${api}/user/login`, requestOptions);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    //console.log('error', error);
    throw error;
  }
}


const rafflesUser = async () => {

  const formdata = new FormData();
  formdata.append("user_id", getCookie('userId'));
  formdata.append("campanha_id", 1);

  const requestOptions = {
    method: 'POST',
    body: formdata,
  };

  try {
    const response = await fetch(`${api}/user/numbers`, requestOptions);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

const createUser = async (userData) => {

  const formdata = new FormData();
  formdata.append("fullName", userData.fullName);
  formdata.append("email", userData.email);
  formdata.append("phoneNumber", userData.phoneNumber);
  formdata.append("cpf", removeNumbers(userData.cpf));

  const requestOptions = {
    method: 'POST',
    body: formdata,
  };

  try {
    const response = await fetch(`${api}/user/create`, requestOptions);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export { createPayment, login, createUser, rafflesUser, getCookie, deleteCookie, startPollingPaymentStatus, api }