import React, { useState, useEffect } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBAccordion,
    MDBAccordionItem,
    MDBCol,
    MDBRow
} from 'mdb-react-ui-kit';
import { login, rafflesUser, createUser } from '../controllers/apiController';
import { GoNumber } from "react-icons/go";

const AuthModal = ({ basicModal, setBasicModal, onLoginSuccess }) => {

    const validaCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
        if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false; // Verifica o tamanho e sequências iguais

        let soma = 0;
        let resto;

        // Primeiro dígito verificador
        for (let i = 1; i <= 9; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.substring(9, 10))) return false;

        soma = 0;
        // Segundo dígito verificador
        for (let i = 1; i <= 10; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.substring(10, 11))) return false;

        return true;
    }

    const [isLogin, setIsLogin] = useState(true); // True para login, false para cadastro
    const [formData, setFormData] = useState({
        cpf: '',
        fullName: '',
        email: '',
        phoneNumber: '',
    });

    const toggleOpen = () => setBasicModal(!basicModal);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const auth = async () => {

        if (!validaCPF(formData.cpf.replace(/[^\d]+/g, ''))) {
            alert('O número de CPF que você informou é inválido.')
            return;
        }

        let loginData = { cpf: formData.cpf };
        try {
            const result = await login(loginData);
            if (result.success) {
                let dataExpiracao = new Date();
                dataExpiracao.setTime(dataExpiracao.getTime() + (7 * 24 * 60 * 60 * 1000)); // Define a data de expiração para 7 dias a partir de agora
                let expires = "expires=" + dataExpiracao.toUTCString();
                document.cookie = `userId=${result.user_id}; ${expires}; path=/`;
                if (typeof onLoginSuccess === 'function') {
                    onLoginSuccess();
                }
            } else {
                setIsLogin(false);
            }
        } catch (error) {
            console.error("Falha ao criar pagamento:", error);
            alert('Houve um erro inesperado. Tente Novamente.');
        }
    }

    const register = async () => {

        if (!validaCPF(formData.cpf.replace(/[^\d]+/g, ''))) {
            alert('O número de CPF que você informou é inválido.')
            return;
        }

        let registerData = { cpf: formData.cpf.replace(/[^\d]+/g, ''), email: formData.email, fullName: formData.fullName, phoneNumber: formData.phoneNumber };

        try {
            const result = await createUser(registerData);
            if (result.success) {
                let dataExpiracao = new Date();
                dataExpiracao.setTime(dataExpiracao.getTime() + (7 * 24 * 60 * 60 * 1000)); // Define a data de expiração para 7 dias a partir de agora
                let expires = "expires=" + dataExpiracao.toUTCString();
                document.cookie = `userId=${result.user_id}; ${expires}; path=/`;
                setBasicModal(false);
            } else {
                console.log(result.message);
                setIsLogin(false);
                alert(result.message);
            }
        } catch (error) {
            console.error("Falha ao criar pagamento:", error);
            alert('Houve um erro inesperado. Tente Novamente.');
        }
    }

    return (
        <>
            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{isLogin ? 'Faça seu login' : 'Faça seu cadastro'}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>{isLogin ? 'Para continuar insira seu CPF abaixo e clique em "continuar".' : 'Preencha os campo abaixo e clicar em "Continuar" para prosseguir com seu cadastro.'}</p>
                            <form>
                                <MDBInput className='mb-4' type='text' name='cpf' value={formData.cpf} onChange={handleChange} label='CPF' />
                                {!isLogin && (
                                    <>
                                        <MDBInput className='mb-4' type='text' name='fullName' value={formData.fullName} onChange={handleChange} label='Nome completo' />
                                        <MDBInput className='mb-4' type='email' name='email' value={formData.email} onChange={handleChange} label='E-mail' />
                                        <MDBInput className='mb-4' type='text' name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} label='Telefone' />
                                    </>
                                )}
                                <p style={{ fontSize: '0.8rem' }}>Ao clicar em continuar, você concorda com nossos <a style={{ color: '#c20303' }}>Termos de uso</a> e <a style={{ color: '#c20303' }}>Política de Privacidade</a></p>
                            </form>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn className='btn-auth' onClick={() => (isLogin ? auth() : register())}>
                                Continuar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

const NumbersModal = ({ basicModal, setBasicModal }) => {
    const toggleOpen = () => setBasicModal(!basicModal);
    // Inicializa data como um objeto com as propriedades esperadas
    const [data, setData] = useState([]);

    const getNumbers = async () => {
        try {
            const result = await rafflesUser();
            if (result.success) {
                setData(result.data);
                console.log('return', data)
                return result.data;
            } else {
                return []; // Retorna estrutura de dados esperada mesmo em falha
            }
        } catch (error) {
            console.error("Falha ao obter números", error);
            alert('Houve um erro inesperado. Tente Novamente.');
            return []; // Retorna estrutura de dados esperada em erro
        }
    };

    useEffect(() => {
        getNumbers();
    }, [basicModal]); // Dependências vazias significam que isso é executado apenas na montagem do componente

    return (
        <>
            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle><GoNumber /> Meus Números</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBAccordion borderless initialActive={1}>
                                <MDBAccordionItem collapseId={1} headerTitle='Seus números'>
                                    <p style={{ textAlign: 'justify' }}>Aqui você pode encontra todos os números que você adquiriu e/ou reservou.
                                        Números com "??????" são números que foram reservados e que aguardam confirmação de pagamento.</p>
                                    <MDBRow className='numbers-container'>
                                        {data && data.map((item, index) => (
                                            <MDBCol size={3} key={index}> {/* Chave movida para aqui */}
                                                <p className={item.raffle_number === '??????' ? 'numbers-reservados' : 'numbers-ativos'}>{item.raffle_number}</p>
                                            </MDBCol>
                                        ))}
                                    </MDBRow>
                                </MDBAccordionItem>
                            </MDBAccordion>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                Fechar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
};

const LogoutModal = ({basicModal, setBasicModal, logout}) => {

    const toggleOpen = () => setBasicModal(!basicModal);

    return (
        <>
            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1' staticBackdrop>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Você deseja mesmo sair?</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalFooter>
                            <MDBBtn className='bkg-default' onClick={toggleOpen}>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn onClick={() => logout()}  className='bkg-default-outline'>Continuar</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export { AuthModal, NumbersModal, LogoutModal }