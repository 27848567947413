import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IoLogoWhatsapp } from "react-icons/io5";

// Importe seus componentes
import RifaPage from '../components/raffle'; // Certifique-se de que o caminho está correto
import Index from '../screens/mainPage';
import ThanksPage from '../screens/thanks';
import PendentePage from '../screens/pending';
import ErrorPage from '../screens/error';
import { NotFoundComponent } from '../components/notFound';
import PaymentPage from '../screens/payment';

// Função que define as rotas
function RoutesController() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/rifa/:id" element={<RifaPage />} /> {/* Rota dinâmica */}
        <Route path="/pagamento" element={<PaymentPage />} />
        <Route path="/obrigado" element={<ThanksPage />} />
        <Route path="/pendente" element={<PendentePage />} />
        <Route path="/ops" element={<ErrorPage />} />

        {/* Rota para página não encontrada (404) */}
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
      <a href="https://wa.me/5511945841504?text=Preciso%20de%20ajuda.." className="btn-whatsapp_fixed" target="_blank" rel="noopener noreferrer">
        <IoLogoWhatsapp className="lab la-whatsapp" style={{ color: '#f5f5f5', fontSize: '2.5rem' }} />
      </a>
    </Router>
  );
}

export default RoutesController;
