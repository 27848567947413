import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedDiv = ({ children, direction, velocity = 1,triggerOnScroll = true }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.15,
        skip: !triggerOnScroll,
    });

    const variants = (direction) => {

        return {
            visible: { x: 0, y: 0, opacity: 1 },
            hidden: {
                x: direction === 'left' ? -100 : direction === 'right' ? 100 : 0,
                y: direction === 'top' ? -100 : direction === 'bottom' ? 100 : 0,
                opacity: 0,
            },
        };
    }


    return (
        <motion.div
            ref={triggerOnScroll ? ref : null}
            initial="hidden"
            animate={triggerOnScroll ? (inView ? "visible" : "hidden") : "visible"}
            variants={variants(direction)}
            transition={{ duration: velocity }}
        >
            {children}
        </motion.div>
    );
};

export { AnimatedDiv }