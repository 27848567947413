import React from 'react';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

const FaqComponent = ({style, className}) => {
  return (
    <MDBAccordion initialActive='0' className={`${className}`} style={style}>
      <MDBAccordionItem collapseId='faq1' headerTitle='Acessando suas compras'>
        Para visualizar suas cotas adaquiridas, clique em "Meus Números" no menu superior do site, digite seu CPF para 
        fazer login, caso ainda não tenha feito, e clique novamente em "Meus Números".
      </MDBAccordionItem>
      <MDBAccordionItem collapseId='faq2' headerTitle='Como é o processo do sorteio?'>
        O sorteio será realizado com base na extração da Loteria Federal.
      </MDBAccordionItem>
      <MDBAccordionItem collapseId='faq3' headerTitle='Onde o prêmio será entregue?'>
        Não há necessidade de se preocupar com os trâmites relacionados à entrega do prêmio, 
        pois nós cuidaremos de tudo.
      </MDBAccordionItem>
    </MDBAccordion>
  );
}

export {FaqComponent}
